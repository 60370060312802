.col {
  width: 50%;
  float: left;
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .col {
    width: 100%;
    float: left;
    padding: 5px;
  }
}
