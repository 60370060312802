.inputText {
  width: 100%;
}

.inputSelect {
  width: 100%;
  height: 24px;
  cursor: pointer;
  display: inline-block;
  border: none;

  &:hover {
    border-radius: 4px;
    background-color: #f8f8f8;
  }
}

.ant-select-selector {
  border-radius: 8px !important;
  border-color: #d8dfe7 !important;
}