@import '../../color.scss';

.inputText {
  width: 100%;
  border: 0;
  padding: 2px 0;
  color: #333;
  width: 275px;
  box-sizing: border-box;
  letter-spacing: 1px;
  visibility: hidden;
  display: none;
}

.edit-wrapper {
  width: 100%;
  float: left;

  display: inline-block;

  padding: 2px 3px;


  .value {
    cursor: pointer;
    height: 24px;
    margin: -4px -3px;
    // &:hover {
      background-image: url(../edit.svg);
      display: inline-block;
      border-radius: 4px;
      background-color: #f8f8f8;
      background-repeat: no-repeat;
      background-position: 99% 4px;
      background-size: 16px;
      vertical-align: middle;
    // }
  }

  .errorLabel {
    display: none;
  }
}

.error {
  .value {
    border-bottom: 1px solid #ff0000;
  }

  .errorLabel {
    display: block;
    color: #ff0000;
  }
}

.good {
  .value {
    border-bottom: 1px solid #66c748;
  }

  .errorLabel {
    display: none;
  }
}

.edit{
  .edit-wrapper{
    visibility: hidden;
    display: none;
  }

  input{
    visibility: visible;
    display: block;
  }
}


.input-text{
  border-radius: 8px !important;
  border-color: #D8DFE7 !important;
}