.container {
  width: 960px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 600px) {
  .container {
    width: 440px;
    margin-right: auto;
    margin-left: auto;
  }
}
