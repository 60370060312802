.inputText {
  width: 100%;

  
}

.value {
  width: 100%;
  cursor: pointer;
  display: inline-block;
}

.inputGroup {
  .inputChildren {
    .ant-picker{
      width: 100%;

     
    }


  }
}

.ant-picker-focused{
  border: none;
}



.date-picker{
  border-radius: 8px !important;
  border-color: #d8dfe7 !important;
}