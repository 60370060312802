@import '../color.scss';

.button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: $border-radius-base;
  margin-right: 10px;

  span {
    .loader {
      float: left;
      display: flex;
      margin: 3px 5px;
      font-size: 4px;
      height: 18px;
      width: 18px;

      &:before {
        width: 10px;
        height: 20px;
        border-radius: 20px 0 0 20px;
        top: -1px;
        left: -1px;
        -webkit-transform-origin: 10px 10px;
        -webkit-animation: load2 2s infinite ease 1.5s;
        animation: load2 2s infinite ease 1.5s;
      }

      &:after {
        width: 10px;
        height: 20px;
        border-radius: 0 20px 20px 0;
        top: -1px;
        left: 9px;
        -webkit-transform-origin: 0px 10px;
        transform-origin: 0px 10px;
        -webkit-animation: load2 2s infinite ease;
        animation: load2 2s infinite ease;
      }
    }
  }
}

.primary {
  background-color: $primary-color;
  border: 0.1rem solid $primary-color;
  color: $initial-color;

  span {
    .loader {

      &:before {
        background-color: $primary-color;
      }

      &:after {
        background-color: $primary-color;
      }
    }
  }

  &:hover {
    background-color: #40a9ff;
    border: 0.1rem solid #40a9ff;
    color: $initial-color;

    span {
      .loader {

        color: #ffffff;

        &:before {
          background-color: #40a9ff;
        }
  
        &:after {
          background-color: #40a9ff;
        }
      }
    }
  }
}

.secondary {
  background-color: #27bf56;
  border: 0.1rem solid #27bf56;
  color: $initial-color;

  span {
    .loader {
      color: $primary-color;

      &:before {
        background-color: #ffffff;
      }

      &:after {
        background-color: #ffffff;
      }
    }
  }
}

.danger {
  background-color: #ff4d4f;
  border: 0.1rem solid #ff4d4f;
  color: $initial-color;

  span {
    .loader {
      color: $primary-color;

      &:before {
        background-color: #ffffff;
      }

      &:after {
        background-color: #ffffff;
      }
    }
  }
}

.outline {
  border: 0.1rem solid $primary-color;
  color: $link-color;

  span {
    .loader {
      color: $primary-color;

      &:before {
        background-color: #ffffff;
      }

      &:after {
        background-color: #ffffff;
      }
    }
  }

  &:hover {
    background-color: $primary-color;
    border: 0.1rem solid $primary-color;
    color: $initial-color;

    span {
      .loader {

        color: #ffffff;

        &:before {
          background-color: $primary-color;
        }
  
        &:after {
          background-color: $primary-color;
        }
      }
    }  
  }
}

.disable {
  cursor: not-allowed;
  color: rgba(0,0,0,0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  &:hover {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0,0,0,0.45);
  }
}
