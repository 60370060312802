.ant-page-header{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.print {
  padding: 50px;
  .header{
    width: 100%;
    display: block;

    padding: 0px 0px 50px 0px;

    .logo {
      height: 130px;
      width: 100%;
      position: relative;
      display: block;

      img {
        position: absolute;
        right: 0px;
      }
    }
  }
  .card {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .card {
    border-top: 1px solid #aab7c0;


  }
  .card:nth-child(2) {
    border-top: none !important;
  }
}

@media print {
  @page {
    size: A4;
    margin: 0mm;
  }
  .ant-page-header{
    display: none;
  }

  .print {
    page-break-inside: avoid;
  }

  .card {
    page-break-before: avoid;

  }
}
