.parent {
  margin-bottom: 20px !important;
}

.ant-collapse-header {
  display: flex;
}
.custom-pannel {
  background-color: #fff;
  border: 1.2px solid;
  border-color: #D8DFE7 !important;
  border-radius: 8px !important;
  padding: 0px !important;
}

.ant-collapse-content {
  border-top: none !important;
  padding-bottom: 10px !important;
  color: rgba(0, 0, 0, 0.85);
}

.ant-collapse-content {
  .ant-collapse-content-box {
   
    padding: 10px !important;
    padding-top: 0px !important;
  }
}

.title{
  color: '#282828';
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}
