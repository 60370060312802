@import '../../color.scss';

.spinner-wrapper {
  position: absolute;
  display: flex;
  background-color: #ffffff;
  z-index: 9999;

  width: 100%;
  height: 100%;

  justify-content: center;
    align-items: center;

  .spinner-body {
    display: block;
    position: absolute;

    
    
    .loader {
      color: $primary-color;
      &:before {
        background-color: #ffffff;
      }
  
      &:after {
        background-color: #ffffff;
      }
    }

    p {
      text-align: center;
      padding: 10px 0px;
    }
  }
}