.inputBox {
    position: relative;
    width: 100%;
    height: 35px;
}

.inputBox input {
    position: relative;
    width: 100%;
}


