.inputGroup {
  display: block;
  width: 100%;
  float: left;
  padding: 5px 5px 5px 0px;

  .label {
    padding-left: 2px;
    margin-bottom: 3px;
  }

  .inputChildren {
    width: auto;
    font-family: 'Verdana';

    .text {
      width: 100%;
      display: inline-block;
    }
  }
}
