.cotainer {
  width: 600px;
}

.logo-header {
  width: 130px;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 0px 50px 0px;

  img {
    width: 130px;
  }
}

.ban {
  width: 100%;
}

.ban-message {
  width: 60%;
  float: left;

  h1 {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Verdana';
  }

  p {
    padding: 0px 0px 30px 0px;
  }
}

.ban-icon {
  width: 40%;
  float: left;
  position: relative;
  display: flex;

  svg {
    height: 270px;
  }
}

@media screen and (max-width: 600px) {
  .cotainer {
    width: 100%;
  }

  .logo-header {
    width: 130px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0px 50px 0px;

    img {
      width: 130px;
    }
  }

  .ban {
    width: 100%;
    padding: 20px;
  }

  .ban-message {
    width: 100%;
    float: left;
  }

  .ban-icon {
   display: none;
  }
}
