.editable-note {
    display: flex;
    gap: 10px;
    // align-items: center;


    span {
        color: black;
        cursor: pointer;
    }
}