.dropDown{
    position: absolute;
    z-index: 999;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    width: 100%;
    display: block;
}

.dropDown div p{
    padding: 2px 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    color: #ffffff;
    background: #1d39c4;
    margin: 0;
}

.dropDown div ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropDown div ul li{
    padding: 2.5px 10px;
}

.dropDown div ul li:hover{
    background: #f4f4f4;
}
